import { useEffect } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import selectors from "lib/redux/selectors";
import { updateDimensions, updateFbPixelValues } from "lib/analytics";
import { ReservationTypes } from "lib/helpers/constants";

export const useAnalytics = () => {
  const context = useSelector((state) => state.context);
  const campaign = useSelector((state) => state.campaign.info);
  const user = useSelector((state) => state.user);
  const loggedIn = useSelector(selectors.user.loggedIn);
  const reservationType = useSelector(selectors.flow.reservationType);
  const defaultView = useSelector(selectors.flow.defaultView);
  const isPremiumReservation = useSelector(selectors.flow.isPremiumReservation);
  const canBuyVoucher = useSelector(selectors.campaign.canBuyVoucher);
  const canBookDate = context.canBookDate && useSelector(selectors.campaign.canBookDate);

  useEffect(
    function updateContextDimensions() {
      updateDimensions({
        // If we have clientPlatform (WEB, Android or ios) we use that to
        // differentiate between the different platforms as clientApplication
        // will always be WEB so that we don't get Middleware request errors.
        dimension11: context.clientPlatform || context.clientApplication,
        dimension16: context.providerId,
      });
    },
    [context]
  );

  useEffect(
    function updateUserDimensions() {
      updateDimensions({
        dimension1: loggedIn,
        dimension6: user.id,
      });
    },
    [user, loggedIn]
  );

  useEffect(
    function updateFlowDimensions() {
      updateDimensions({
        dimension20: reservationType === ReservationTypes.SMART,
      });
    },
    [reservationType]
  );

  useEffect(
    function updateCampaignDimensions() {
      let dimension17 = null;
      if (!campaign.isAvailable) {
        dimension17 = 'Unavailable';
      } else if (get(campaign, 'campaignView.offer', false) && !get(campaign, 'campaignView.isFreeOffer', true)) {
        dimension17 = 'Gift';
      } else if (get(campaign, 'campaignView.offer', false)) {
        dimension17 = 'Booking+Gift';
      } else {
        dimension17 = 'Booking';
      }

      let dimension20;
      if (campaign.premium) {
        dimension20 = "Premium_exclusive"
      } else if (isPremiumReservation) {
        if (canBuyVoucher) {
          dimension20 = "Premium_voucher"
        } else {
          dimension20 = "Premium_booking"
        }
      } else {
        if (canBookDate && defaultView !== "voucher") {
          dimension20 = "Booking"
        } else {
          dimension20 = "Voucher"
        }
      }

      updateDimensions({
        dimension2: get(campaign, "categoryView.categories[0].categoryId", ""),
        dimension3: get(
          campaign,
          "categoryView.categories[0].subcategories[0].subcategoryId",
          ""
        ),
        dimension8: campaign.id || "",
        dimension9: campaign.shopId || "",
        dimension12: campaign.typeId || "",
        // dimension16: campaign.typeId || "",
        dimension17: dimension17,
        dimension18: get(
          campaign,
          "categoryView.categories[0].subcategories[0].subcategoryName",
          ""
        ),
        dimension20,
      });

      updateFbPixelValues({
        content_ids: campaign.id || "",
        content_name: campaign.title || "",
      });
    },
    [campaign]
  );

  return null;
};
