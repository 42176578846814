import React, { useEffect, useRef } from "react";
import { css } from "@emotion/core";
import Footer from "./footer";

const styles = {
  wrapper: css({
    flex: 1,
    minHeight: "100px",
    overflowY: "auto",
    position: 'relative',
  }),
};

export default function FlowStepWrapper({ children }) {
  const ref = useRef();

  useEffect(() => {
      ref.current.scrollTop = 0;
  }, [children]);

  return (
    <div ref={ref} css={styles.wrapper}>
      {children}
    </div>
  );
}
