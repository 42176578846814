import { css } from "@emotion/core";
import { IconWrapper } from "components/icons";
import { formatPrice } from "lib/helpers/utils";
import selectors from "lib/redux/selectors";
import Logo from "public/static/images/logo_red.svg";
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cssVars from "styles/variables.module.scss";

const styles = {
  mobileContainer: css({
    minHeight: "190px",
    maxHeight: "190px",
    height: "190px",
    color: "white",
    borderBottom: `1px solid ${cssVars.gray500}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&.menu": {
      backgroundColor: cssVars.gray400,
    },
  }),
  websiteContainer: css({
    minHeight: "50px",
    height: "auto",
    color: cssVars.gray800,
    backgroundColor: cssVars.gray200,
    borderBottom: `1px solid ${cssVars.gray500}`,
    ".container": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    "&.menu": {
      backgroundColor: cssVars.gray400,
      ".title": {
        fontSize: "1.2rem",
        color: cssVars.black,
        padding: "0.5rem 0.5rem 0.2rem 0.5rem",
      },
    },
  }),
  navRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "65px",
    ".nav-button": {
      minWidth: "24px",
      cursor: "pointer",
    },
  }),
  mobileNavRow: css({
    ".nav-button": {
      svg: {
        fill: "white",
        filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))",
      },
      "&:hover": {
        svg: {
          fill: "white",
          filter: "drop-shadow(2px 2px 3px rgba(0, 0, 0, .7))",
        },
      },
    },
  }),
  websiteNavRow: css({
    flex: 1,
    ".nav-button": {
      svg: {
        fill: cssVars.gray600,
      },
    },
  }),
  shopNameRow: css({
    fontSize: "0.8rem",
    fontWeight: "bold",
    marginBottom: "0.3rem",
    maxHeight: "24px",
    textShadow: "1px 1px 2px rgba(0, 0, 0, .7)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1", // number of lines to show
    WebkitBoxOrient: "vertical",
  }),
  productTitle: css({
    fontSize: "0.9rem",
    fontWeight: 500,
    lineHeight: 1.2,
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "center",
    margin: "0.2rem 0",
  }),
  mobileProductTitle: css({
    maxHeight: "94px",
    display: "-webkit-box",
    textAlign: "left",
    textShadow: "1px 1px 2px rgba(0, 0, 0, .7)",
    WebkitLineClamp: "3", // number of lines to show
    WebkitBoxOrient: "vertical",
  }),
  websiteProductTitleMain: css({
    flex: 1,
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    maxHeight: "2.5em",
    overflow: "hidden",
  }),
  websiteProductTitleSecondary: css({
    marginTop: "0.3rem",
    fontSize: "0.8rem",
    color: cssVars.gray700,
    textAlign: "center",
  }),
  menuDigitalRow: css({
    marginTop: "0.2rem",
    fontSize: "0.8rem",
    color: cssVars.gray700,
    textAlign: "center",
  }),
  poweredByMygonRow: css({
    fontSize: "0.5rem",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0.2rem 0",
    ".logo-wrapper": {
      width: "3rem",
      height: "0.6rem",
      display: "flex",
      marginLeft: "0.1rem",
    },
  }),
};

export default function Header({
  onBack,
  onClose,
  poweredByMygon,
  isMenu,
  showProductName,
}) {
  const { t } = useTranslation();
  const { productTitle: productTitleFromProduct } = useSelector(selectors.product.info);
  const selectedPriceInfo = useSelector(selectors.flow.selectedPriceInfo);
  const isService = !!useSelector(selectors.flow.serviceId);
  const productTitle = isService && selectedPriceInfo.priceBefore > 0
    ? `${productTitleFromProduct} - ${formatPrice(
        selectedPriceInfo.priceBefore,
        selectedPriceInfo.currency
      )}`
    : productTitleFromProduct;

  const theme = isMenu ? "menu" : "";

  return onBack || showProductName ? (
    <div
      className={theme}
      css={[
        styles.websiteContainer,
        poweredByMygon ? styles.poweredByMygonContainer : null,
      ]}
    >
      <Container>
        <div css={[styles.navRow, styles.websiteNavRow]}>
          {onBack ? (
            <div
              aria-label="Back"
              className="nav-button nav-back"
              onClick={onBack}
            >
              <IconWrapper
                name="mdiChevronLeft"
                width="1.333rem"
                height="2rem"
                viewBox="8 0 12 24"
              />
            </div>
          ) : (
            <div className="nav-button nav-back"></div>
          )}
          <div
            className="title"
            css={[styles.productTitle, styles.websiteProductTitleMain]}
            title={productTitle}
          >
            {productTitle}
          </div>
          {onClose ? (
            <div
              aria-label="Back"
              className="nav-button nav-close"
              onClick={onClose}
            >
              <IconWrapper
                name="mdiCloseCircle"
                width="1.6rem"
                height="1.6rem"
              />
            </div>
          ) : (
            <div className="nav-button nav-close"></div>
          )}
        </div>
        {isMenu && (
          <div css={styles.menuDigitalRow}>{t("menu.digitalMenu")}</div>
        )}
        {poweredByMygon && (
          <div css={styles.poweredByMygonRow}>
            <span>Powered by</span>
            <span className="logo-wrapper">
              <Logo width="100%" height="100%" viewBox="0 0 140 30" />
            </span>
          </div>
        )}
      </Container>
    </div>
  ) : null
}
