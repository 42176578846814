import selectors from "lib/redux/selectors";
import React, {useState} from "react";
import { useSelector } from "react-redux";

export default function ImageHeader({}) {
  const [ campaignImage ] = useSelector(selectors.campaign.images)
  const shopImages = useSelector(selectors.shop.images)
  const image = campaignImage || shopImages

  const [imageError, setImageError] = useState(false)
  const handleImageError = () => {
    setImageError(true);
  }

  const { shopName: shopNameFromProduct } = useSelector(selectors.product.info)
  const shopNameFromShop = useSelector((state) => state.shop.name)
  const shopName = shopNameFromShop || shopNameFromProduct

  return (
    <div className="image-header-container">
      {imageError ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="52px"
          className="image-header"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <rect width="100" height="100" fill="#ccc" />
        </svg>
      ) : (
        <img
          src={image}
          className="image-header"
          onError={handleImageError}
          alt="shop-image"
        />
      )}

      <div className={imageError ? 'image-header-title missing-image' : 'image-header-title'}>
        {shopName}
      </div>
    </div>
  )
}
