import React from "react";
import { Spinner } from "react-bootstrap";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { useFlow } from "lib/hooks/use-flow";
import { useAnalytics } from "lib/hooks/use-analytics";
import FlowStepWrapper from "components/flow-step-wrapper";
import Header from "components/header";
import ButtonNextStep from "components/elements/button-next-step";
import ImageHeader from "./image-header";

const styles = {
  spinnerOverlay: css({
    position: "fixed",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,.8)",
    flexDirection: "column",
    ".message": {
      padding: "1rem",
    },
  }),
  notReadyContainer: css({
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
};

const Widget = ({ queryOverrides, showShopName }) => {
  const {
    ready,
    campaignError,
    step,
    stepComponent,
    formikProps,
    onClose,
    onNextStep,
    onPrevStep,
    headerProps,
    nextStepLabel,
  } = useFlow({ queryOverrides });
  useAnalytics();

  const { t } = useTranslation();

  if (campaignError) {
    return (
      <div css={styles.notReadyContainer}>
        <div>{campaignError}</div>
      </div>
    );
  } else if (ready) {
    return (
      <div className="widget-container">
        <ImageHeader />

        <div className="content-container">
          <Header onBack={onPrevStep} onClose={onClose} {...headerProps} />
          {formikProps.waitingOverlay && (
            <div css={styles.spinnerOverlay}>
              <Spinner animation="border" role="status">
              </Spinner>
              <span className="sr-only">{t("waiting")}...</span>
              {formikProps.waitingOverlayText && (
                <span className="message">{formikProps.waitingOverlayText}</span>
              )}
            </div>
          )}
          <FlowStepWrapper step={step}>{stepComponent}</FlowStepWrapper>
          <ButtonNextStep formikProps={formikProps}>
            {nextStepLabel}
          </ButtonNextStep>
        </div>
      </div>
    );
  } else {
    return (
      <div css={styles.notReadyContainer}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t("loading")}...</span>
        </Spinner>
      </div>
    );
  }
};

export default Widget;
